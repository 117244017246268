import createSagaMiddleware, { Task } from 'redux-saga'
import { createStore, applyMiddleware, Store } from 'redux'
import { createWrapper, MakeStore } from 'next-redux-wrapper'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

import { IStore } from '@Interfaces'
import { NTABService } from '@Services'

import rootSaga from './sagas'
import reducers from './reducers'

export interface SagaStore extends Store {
  sagaTask: Task
}

const makeStore: MakeStore<IStore> = () => {
  const sagaMiddleware = createSagaMiddleware()

  const store: any = createStore(reducers, composeWithDevTools(applyMiddleware(sagaMiddleware)))

  store.sagaTask = sagaMiddleware.run(rootSaga, { NTABService })

  return store
}

export const wrapper = createWrapper<IStore>(makeStore, { debug: true })
