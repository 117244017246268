import App, { AppContext } from 'next/app'
import Head from 'next/head'
import * as React from 'react'
import { JssProvider } from 'react-jss'
import { MuiThemeProvider } from '@material-ui/core'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import CssBaseline from '@material-ui/core/CssBaseline'
import { StylesProvider } from '@material-ui/core/styles'
import cookies from 'next-cookies'
import { END } from 'redux-saga'

import { Http } from '@Services'
import { IApp } from '@Interfaces'
import { defaultTheme } from '@Themes'
import { RuntimeActions } from '@Redux/actions'
import { wrapper as reduxWrapper, SagaStore } from '@Redux/store'

import { appWithTranslation } from '../i18n'

const GlobalStyle = createGlobalStyle``

class MyApp extends App<IApp.IProps> {
  static async getInitialProps({ ctx, Component }: AppContext) {
    let pageProps = {}
    let token = undefined

    const ctxCookies: any = cookies(ctx)

    // Set the cookie stored lang preference on the HTTP service
    if (ctxCookies['next-i18next']) {
      Http.SetLanguage(ctxCookies['next-i18next'])
    }

    // Set the cookie stored access token on the HTTP service

    if (ctxCookies.jwtIdToken) {
      token = ctxCookies.jwtIdToken
      Http.SetAccessToken(token)
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (ctx.store) {
        ctx.store.dispatch(RuntimeActions.SetIsAuthenticated(true))
      }
    }

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    if (ctx.req && ctx.store) {
      ctx.store.dispatch(END)
      await (ctx.store as SagaStore).sagaTask.toPromise()
    }

    return { pageProps, token }
  }

  componentDidMount() {
    const { token } = this.props
    const jssStyles: any = document.querySelector('#jss-server-side')

    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles)
    }

    if (token) {
      Http.SetAccessToken(token)
    }
  }

  render(): JSX.Element {
    const { Component, pageProps } = this.props

    return (
      <StylesProvider injectFirst>
        <JssProvider>
          <MuiThemeProvider theme={defaultTheme}>
            <ThemeProvider theme={defaultTheme}>
              <>
                <Head>
                  <title>NTAB Portal</title>
                  <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/images/apple-touch-icon.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/images/favicon-32x32.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/images/favicon-16x16.png"
                  />
                  <link
                    href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap"
                    rel="stylesheet"
                  />
                  <link rel="manifest" href="/images/site.webmanifest" />
                  <link rel="mask-icon" href="/images/safari-pinned-tab.svg" color="#5bbad5" />
                  <link rel="shortcut icon" href="/images/favicon.ico" />
                  <meta name="msapplication-TileColor" content="#da532c" />
                  <meta name="msapplication-config" content="/images/browserconfig.xml" />
                  <meta name="theme-color" content="#ffffff" />
                </Head>
                <GlobalStyle />
                <CssBaseline />
                <Component {...pageProps} />
              </>
            </ThemeProvider>
          </MuiThemeProvider>
        </JssProvider>
      </StylesProvider>
    )
  }
}

export default reduxWrapper.withRedux(appWithTranslation(MyApp))
