import { call, put, takeEvery } from 'redux-saga/effects'

import { ActionConsts } from '@Definitions'
import { ResourcesActions } from '@Redux/actions'
import { IServices, IAction, IHttp } from '@Interfaces'

function* watchReadResourceStart(
  NTABService: any, // eslint-disable-line
  {
    payload: { resource, id, relation, relationId },
  }: IAction<{ resource: string; id: number; relation: string; relationId: number }>,
) {
  const response: IHttp.Response = yield call(
    [NTABService, NTABService.ReadResource],
    resource,
    id,
    relation,
    relationId,
  )

  if (response.success) {
    let data = response.data

    if (!Array.isArray(data)) {
      data = [data]
    }

    yield put(ResourcesActions.ReadResourceSuccess(resource, data))
  } else {
    yield put(ResourcesActions.ReadResourceFailure(resource, response.error || ''))
  }
}

export default function*({ NTABService }: IServices) {
  yield takeEvery(ActionConsts.Resources.ReadResourceStart, watchReadResourceStart, NTABService)
}
