import Router from 'next/router'
import { call, select, put, takeLatest } from 'redux-saga/effects'

import { ActionConsts } from '@Definitions'
import { RuntimeActions } from '@Redux/actions'
import { IServices, IAction, IHttp, IStore } from '@Interfaces'
import { ResourcesActions } from '@Redux/resources/actions'
import { getIsAuthenticated } from '@Redux/runtime/selectors'

function* watchSetCurrentProjectStart(
  NTABService: any,
  { payload: { id } }: IAction<{ id: number }>,
) {
  const response: IHttp.Response = yield call(
    [NTABService, NTABService.ReadResource],
    'projects',
    id,
  )

  if (response.success) {
    yield put(RuntimeActions.SetCurrentProjectSuccess(response.data))
  } else {
    yield put(RuntimeActions.SetCurrentProjectFailure(response.error))
  }
}

function* watchLogout(NTABService: any) {
  yield put(ResourcesActions.Reset())
  yield put(RuntimeActions.Reset())
  yield put(RuntimeActions.SetIsAuthenticated(false))
  yield put(RuntimeActions.SetCurrentUser(null))
  NTABService.Logout()
}

function* watchAadLoginSuccess(
  NTABService: any,
  { payload: { jwtIdToken } }: IAction<{ jwtIdToken: string }>,
) {
  const isAuthenticated: boolean = yield select((state: IStore) =>
    getIsAuthenticated(state.runtime),
  )

  if (!isAuthenticated) {
    NTABService.SetAuth(jwtIdToken)

    const currentUser: any = yield call([NTABService, NTABService.GetAuthorizedUser])

    if (currentUser.success) {
      yield put(RuntimeActions.SetCurrentUser(currentUser.data))
    }

    yield put(RuntimeActions.SetIsAuthenticated(true))
    Router.replace(Router.asPath === '/' ? '/projects' : Router.asPath)
  }
}

function* watchRefreshToken(NTABService: any) {
  let result = yield call([NTABService, NTABService.GetAuthorizedUser])

  if (!result.success) {
    result = yield call([NTABService, NTABService.GetAuthorizedUser])

    if (!result.success) {
      yield call([NTABService, NTABService.Logout])
    }
  }
}

export default function*({ NTABService }: IServices) {
  yield takeLatest(
    ActionConsts.Runtime.SetCurrentProjectStart,
    watchSetCurrentProjectStart,
    NTABService,
  )
  yield takeLatest(ActionConsts.Runtime.Logout, watchLogout, NTABService)
  yield takeLatest(ActionConsts.Runtime.SSOLoginSuccess, watchAadLoginSuccess, NTABService)
  yield takeLatest(ActionConsts.Runtime.RefreshToken, watchRefreshToken, NTABService)
}
