const NextI18Next = require('next-i18next').default

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: ['tr'],
  localePath: 'locales',
  detection: {
    cookieSecure: process.env.USE_HTTPS === '1',
    cookieSameSite: 'strict',
  },
})

module.exports = NextI18NextInstance
