import { all, fork } from 'redux-saga/effects'

import runtimeSaga from './runtime/saga'
import resourcesSaga from './resources/saga'

const sagas: any[] = [runtimeSaga, resourcesSaga]

export default function* rootSaga(services = {}) {
  yield all(sagas.map(saga => fork(saga, services)))
}
