import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

const palette = {
  primary: {
    light: '#9FB4C2',
    main: '#0B4365',
    dark: '#093550',
    contrastText: '#fff',
  },
  secondary: {
    main: '#DC082C',
    contrastText: '#fff',
  },
  error: {
    main: red.A400,
  },
  background: {
    default: '#fff',
    paper: '#fff',
  },
  text: {
    primary: '#4D4D4D',
    secondary: '#ffffff',
  },
}

export const defaultTheme = createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 992,
      xl: 1440,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 800,
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
      sizeLarge: {
        padding: '13px 32px',
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: '100px',
      },
    },
    MuiFormLabel: {
      root: {
        color: palette.primary.main,
      },
    },
    MuiInput: {
      underline: {
        after: {
          borderColor: palette.primary.main,
        },
      },
    },
  },
  palette,
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h4: {
      fontWeight: 800,
      color: palette.primary.main,
    },
  },
})
